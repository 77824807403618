import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationEllipsis,
  PaginationNext,
} from "@fscrypto/ui";

type PagingProps = {
  page: number;
  pageSize: number;
  totalResults: number;
  getPagedUrl: (page: number) => string;
};

export const Paging = (props: PagingProps) => {
  const { page, pageSize, totalResults, getPagedUrl } = props;

  const pagingShouldDisplay = totalResults > pageSize;
  const pagingHasMore = totalResults > pageSize * page;
  const pagingShowLowElipsis = page > 3;
  const pagingShowHighElipsis = page < Math.ceil(totalResults / pageSize) - 2;
  const pagingHasLess = page > 1;
  const lastPageNumber = Math.ceil(totalResults / pageSize);
  const prevPageNumber = page - 1;
  const nextPageNumber = page + 1;

  if (!pagingShouldDisplay) {
    return <div className="m-8"></div>;
  }

  return (
    <Pagination className="m-8">
      <PaginationContent>
        {pagingHasLess && (
          <>
            <PaginationItem>
              <PaginationPrevious to={getPagedUrl(prevPageNumber)} />
            </PaginationItem>
          </>
        )}

        <PaginationItem>
          <PaginationLink to={getPagedUrl(1)} isActive={page === 1}>
            1
          </PaginationLink>
        </PaginationItem>

        {pagingShowLowElipsis && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}

        {prevPageNumber !== 1 && pagingHasLess && prevPageNumber !== lastPageNumber && (
          <PaginationItem>
            <PaginationLink to={getPagedUrl(prevPageNumber)}>{prevPageNumber}</PaginationLink>
          </PaginationItem>
        )}

        {page !== 1 && page !== lastPageNumber && (
          <PaginationItem>
            <PaginationLink to={getPagedUrl(page)} isActive>
              {page}
            </PaginationLink>
          </PaginationItem>
        )}

        {pagingHasMore && nextPageNumber !== lastPageNumber && (
          <PaginationItem>
            <PaginationLink to={getPagedUrl(nextPageNumber)}>{nextPageNumber}</PaginationLink>
          </PaginationItem>
        )}

        {pagingShowHighElipsis && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}

        <PaginationItem>
          <PaginationLink to={getPagedUrl(lastPageNumber)} isActive={page === lastPageNumber}>
            {lastPageNumber}
          </PaginationLink>
        </PaginationItem>

        {pagingHasMore && (
          <PaginationItem>
            <PaginationNext to={getPagedUrl(page + 1)} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};
